<template>
  <div class="flex flex-col mb-4">
    <label
      :for="label"
      class="text-m-form xl:text-base mb-2"
    >{{ label }}</label>
    <span class="relative w-full">
      <input
        v-model="inputValue"
        :name="label"
        :type="inputType"
        class="form-input w-full text-m-form xl:text-base p-3"
        :class="valid ? '' : 'border-red-600 border-2'"
        :placeholder="placeholder"
        @input="emitInputData"
        @blur="emitBlur"
      >
    </span>
    <div
      v-show="!valid"
      class="flex flex-row text-m-form xl:text-base pt-2"
    >
      <img
        src="@/assets/images/error.svg"
        alt=""
        class="pr-1"
      >
      <p
        v-show="!valid"
        class="xl:text-sm text-xs text-red-600"
      >
        {{ errorMsg }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

const props = defineProps({
	label: String,
	inputKey: String,
	type: String,
	placeholder: String,
	modelValue: String,
	valid: {
		type: Boolean,
		required: false,
		default: true,
	},
	errorMsg: {
		type: String,
		required: false,
	},
});

const emit = defineEmits(["update:modelValue", "input", "blur"]);

const inputType = ref(props.type);
const inputValue = ref(props.modelValue);

watch(inputValue, (newValue) => {
	emit("update:modelValue", newValue);
});

function emitInputData(event: any) {
	emit("input", props.inputKey, inputValue.value);
	inputValue.value = formatValue(event.target.value);
}

function formatValue(value: string) {
	let cleaned = value.replace(/[^\d]/g, ""); // This will remove non-digits

	if (cleaned.length > 8) {
		cleaned = cleaned.slice(0, 8); // This is to ensure the value is no longer than 'MMDDYYYY'
	}

	let formatted = cleaned;

	// Apply formatting
	if (cleaned.length > 4) {
		// 'MM', 'DD', 'YYYY'`
		formatted = `${cleaned.slice(0, 2)}/${cleaned.slice(2, 4)}/${cleaned.slice(
			4,
		)}`;
	} else if (cleaned.length > 2) {
		// 'MM', 'DD'
		formatted = `${cleaned.slice(0, 2)}/${cleaned.slice(2)}`;
	} else {
		// 'MM'
		formatted = cleaned;
	}
	return formatted;
}

function emitBlur() {
	emit("blur");
}
</script>
